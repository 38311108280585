<!--
// nuxt-ui/components/pages/region-matches/TableRowMatchMatches.vue
-->
<script setup lang="ts">
import type { Match } from "~/src/match/domain/models/Match";
import type { Team } from "~/src/team/domain/models/Team";
import type { DateTimeFormatOptions } from "@intlify/core-base";
import HomeTeam from "~/nuxt-ui/components/pages/region-matches/HomeTeam.vue";
import AwayTeam from "~/nuxt-ui/components/pages/region-matches/AwayTeam.vue";
import matchUiService from "~/src/match/infrastructure/ui-services/matchUiService";
import { useMatches } from "~/nuxt-ui/Shared/composables/season/matches";
import { useTeams } from "~/nuxt-ui/Shared/composables/teams";

type Props = {
  match: Match;
  awayTeam: Team | undefined;
  homeTeam: Team | undefined;
  showGroup: boolean;
  seasonId?: number;
  timeZone?: string;
  regionParam?: string;
};

const props = defineProps<Props>();

const { locale } = useI18n();
const localePath = useLocalePath();
const route = useRoute();
const { transformStatus } = matchUiService();
const { formatMatchTime } = useMatches();
const { getSeasonTeamPlayers } = useTeams();

const matchTime = formatMatchTime(locale.value, props.match.date, props.regionParam, props.timeZone);

const dateOptions: DateTimeFormatOptions = {
  month: "2-digit",
  day: "numeric",
  year: "2-digit",
  timeZone: props.timeZone,
};

const getWinner = (match: Match): string => {
  if (match.scores.homeScore > match.scores.awayScore) {
    return "home";
  } else if (match.scores.homeScore < match.scores.awayScore) {
    return "away";
  } else {
    if (match.scores.homeScoreP > match.scores.awayScoreP) {
      return "home";
    }
    if (match.scores.homeScoreP < match.scores.awayScoreP) {
      return "away";
    }
  }
};

const showDateTime = time => {
  return (
    time === "0:00h ESP" ||
    time === "12:00 AM ESP" ||
    time === "0:00h CDMX" ||
    time === "12:00 AM CDMX" ||
    time === "00:00h ESP" ||
    time === "00:00h CDMX"
  );
};

const playersHomeTeam =
  props.seasonId && !props.homeTeam?.isPlaceholder
    ? await getSeasonTeamPlayers(props.seasonId, props.homeTeam?.id)
    : undefined;

const playersAwayTeam =
  props.seasonId && !props.homeTeam?.isPlaceholder
    ? await getSeasonTeamPlayers(props.seasonId, props.awayTeam?.id)
    : undefined;
</script>

<template>
  <div>
    <div class="relative flex pt-8 px-3 pb-3 rounded-lg bg-gray-800 mb-2 items-center lg:p-4">
      <div class="w-20 mr-10 text-gray-400 hidden lg:block" v-if="match.date">
        {{ new Date(match.date).toLocaleDateString(locale, dateOptions) }}
      </div>
      <div
        class="min-w-[6rem] text-gray-400 text-xs lg:text-base hidden sm:block"
        v-if="match.date && !showDateTime(matchTime)"
      >
        {{ matchTime }}
      </div>
      <div v-else class="min-w-[6rem] text-gray-400 text-center text-xs lg:text-base hidden sm:block">-</div>

      <div
        :class="[
          'grid grid-cols-[1fr_auto_1fr] lg:gap-x-5 gap-x-1 w-full px-2.5 lg:pl-0 items-center',
          showGroup ? '-ml-9' : 'lg:pr-6',
        ]"
      >
        <div>
          <home-team v-if="homeTeam" :team="homeTeam" :isWinner="getWinner(match) === 'home'" is-kok />
          <div v-if="!!playersHomeTeam" class="flex flex-wrap gap-x-2 justify-end">
            <p v-for="player in playersHomeTeam" class="text-xs text-right">{{ player.shortName }}</p>
          </div>
        </div>

        <div class="flex justify-center items-baseline">
          <span
            v-if="typeof match.scores.homeScoreP === 'number'"
            class="font-bold text-sm text-primary mx-1"
          >
            {{ match.scores.homeScoreP }}
          </span>
          <p class="font-bold text-lg" :class="{ 'ml-1': !match.scores.homeScoreP }">
            {{ match.scores.homeScore === null ? "-" : match.scores.homeScore }}
          </p>
          <p class="text-gray-400 text-xs lg:text-sm lg:mx-3.5 mx-1 self-center">VS</p>
          <p class="font-bold text-lg" :class="{ 'mr-1': !match.scores.awayScoreP }">
            {{ match.scores.awayScore === null ? "-" : match.scores.awayScore }}
          </p>
          <span
            v-if="typeof match.scores.awayScoreP === 'number'"
            class="font-bold text-sm text-primary mx-1"
          >
            {{ match.scores.awayScoreP }}
          </span>
        </div>

        <div>
          <away-team v-if="awayTeam" :team="awayTeam" :isWinner="getWinner(match) === 'away'" is-kok />
          <div v-if="playersAwayTeam" class="flex flex-wrap gap-x-2">
            <p v-for="player in playersAwayTeam" class="text-xs">{{ player.shortName }}</p>
          </div>
        </div>
      </div>

      <p
        :class="[
          'text-gray-400 text-right text-[10px] lg:text-sm absolute top-2 right-3 lg:static',
          showGroup ? 'lg:w-20' : 'lg:w-32 lg:ml-auto',
        ]"
      >
        <span v-if="match.status">
          {{ $t(transformStatus(match.status)) }}
        </span>
      </p>

      <div
        :class="[
          'flex w-fit lg:ml-10 lg:w-20 absolute top-2 left-3 gap-x-1.5 justify-center items-center',
          showGroup ? 'lg:!static' : 'lg:hidden',
        ]"
      >
        <p
          v-if="showGroup"
          class="rounded border-primary border w-fit text-primary lg:px-2 lg:py-0.5 ml-auto px-1 text-[10px] lg:text-sm font-normal lg:font-medium"
        >
          {{ match.groupName }}
        </p>
        <div class="w-20 text-gray-400 text-xs sm:hidden" v-if="match.date && !showDateTime(matchTime)">
          {{ matchTime }}
        </div>
        <div v-else class="w-20 text-gray-400 text-xs sm:hidden">-</div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
