<!--
// nuxt-ui/components/pages/region-matches/TableRowMatchMatches.vue
-->
<script setup lang="ts">
import type { Match } from "~/src/match/domain/models/Match";
import type { Team } from "~/src/team/domain/models/Team";
import type { DateTimeFormatOptions } from "@intlify/core-base";
import HomeTeam from "~/nuxt-ui/components/pages/region-matches/HomeTeam.vue";
import AwayTeam from "~/nuxt-ui/components/pages/region-matches/AwayTeam.vue";
import nuxtLink from "#app/components/nuxt-link";
import matchUiService from "~/src/match/infrastructure/ui-services/matchUiService";
import matchService from "~/src/match/domain/services/matchService";
import { useMatches } from "~/nuxt-ui/Shared/composables/season/matches";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { slugify } from "~/src/Shared/utils";

type Props = {
  match: Match;
  awayTeam: Team | undefined;
  homeTeam: Team | undefined;
  showGroup: boolean;
};

const props = defineProps<Props>();

const { locale } = useI18n();
const localePath = useLocalePath();
const route = useRoute();
const { regionParam, regionTimeZone } = useRegions();
const { matchHasPage } = matchService();
const { transformStatus } = matchUiService();
const { formatMatchTime } = useMatches();

const matchTime = formatMatchTime(locale.value, props.match.date, regionParam, regionTimeZone);

const dateOptions: DateTimeFormatOptions = {
  month: "2-digit",
  day: "numeric",
  year: "2-digit",
  timeZone: regionTimeZone,
};

const showDateTime = time => {
  return (
    time === "0:00h ESP" ||
    time === "12:00 AM ESP" ||
    time === "0:00h CDMX" ||
    time === "12:00 AM CDMX" ||
    time === "00:00h ESP" ||
    time === "00:00h CDMX"
  );
};

const getWinner = (match: Match): string => {
  if (match.scores.homeScore > match.scores.awayScore) {
    return "home";
  } else if (match.scores.homeScore < match.scores.awayScore) {
    return "away";
  } else {
    if (match.scores.homeScoreP > match.scores.awayScoreP) {
      return "home";
    }
    if (match.scores.homeScoreP < match.scores.awayScoreP) {
      return "away";
    }
  }
};
</script>

<template>
  <component
    :is="matchHasPage(match.id) ? nuxtLink : 'div'"
    :to="
      matchHasPage(match.id)
        ? localePath({
            name: 'region-matches-matchId-homeTeam-vs-awayTeam',
            params: {
              region: route.params.region,
              matchId: match.id,
              homeTeam: slugify(homeTeam?.shortName),
              awayTeam: slugify(awayTeam?.shortName),
            },
          })
        : undefined
    "
  >
    <div class="relative flex pt-8 px-3 pb-3 rounded-lg bg-gray-800 mb-2 items-center lg:p-4">
      <div class="w-20 mr-10 text-gray-400 hidden lg:block" v-if="match.date">
        {{ new Date(match.date).toLocaleDateString(locale, dateOptions) }}
      </div>
      <div
        class="min-w-[6rem] text-gray-400 text-xs lg:text-base hidden sm:block"
        v-if="match.date && !showDateTime(matchTime)"
      >
        {{ matchTime }}
      </div>
      <div v-else class="min-w-[6rem] text-gray-400 text-center text-xs lg:text-base hidden sm:block">-</div>

      <div
        :class="[
          'mx-auto flex lg:gap-x-5 gap-x-1 items-center w-full px-2.5 lg:pl-0 justify-center',
          showGroup ? '-ml-9' : 'lg:pr-6',
        ]"
      >
        <home-team :team="homeTeam" :isWinner="getWinner(match) === 'home'" v-if="homeTeam" />
        <div class="flex justify-center items-baseline">
          <span
            v-if="typeof match.scores.homeScoreP === 'number'"
            class="font-bold text-sm text-primary mx-1"
          >
            {{ match.scores.homeScoreP }}
          </span>
          <p class="font-bold text-lg">
            {{ match.scores.homeScore === null ? "-" : match.scores.homeScore }}
          </p>
          <p class="text-gray-400 text-xs lg:text-sm lg:mx-3.5 mx-1 self-center">VS</p>
          <p class="font-bold text-lg">
            {{ match.scores.awayScore === null ? "-" : match.scores.awayScore }}
          </p>
          <span
            v-if="typeof match.scores.awayScoreP === 'number'"
            class="font-bold text-sm text-primary mx-1"
          >
            {{ match.scores.awayScoreP }}
          </span>
        </div>
        <away-team :team="awayTeam" :isWinner="getWinner(match) === 'away'" v-if="awayTeam" />
      </div>

      <div class="w-12 lg:hidden">
        <svg
          class="ml-auto"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_141_13111)">
            <path
              d="M3.67863 12.5C3.44839 12.4999 3.22332 12.4296 3.0319 12.2979C2.84047 12.1662 2.69128 11.9791 2.60317 11.7601C2.51507 11.5411 2.49201 11.3002 2.53692 11.0677C2.58182 10.8353 2.69267 10.6217 2.85546 10.4541L6.68957 6.50736L2.85546 2.56061C2.74425 2.45005 2.65555 2.3178 2.59453 2.17158C2.53351 2.02535 2.50139 1.86808 2.50004 1.70894C2.4987 1.5498 2.52816 1.39198 2.5867 1.24469C2.64525 1.09739 2.7317 0.963573 2.84102 0.85104C2.95034 0.738507 3.08034 0.649513 3.22343 0.58925C3.36653 0.528987 3.51984 0.498662 3.67444 0.500045C3.82904 0.501428 3.98182 0.534491 4.12387 0.597305C4.26593 0.660118 4.3944 0.751425 4.50181 0.865896L9.1591 5.66001C9.37738 5.88476 9.5 6.18956 9.5 6.50736C9.5 6.82517 9.37738 7.12997 9.1591 7.35472L4.50181 12.1488C4.28351 12.3736 3.9874 12.4999 3.67863 12.5Z"
              fill="#E35205"
            />
          </g>
          <defs>
            <clipPath id="clip0_141_13111">
              <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <p
        :class="[
          'text-gray-400 text-right text-[10px] lg:text-sm absolute top-2 right-3 lg:static',
          showGroup ? 'lg:w-20' : 'lg:w-32 lg:ml-auto',
        ]"
      >
        <span v-if="match.status">
          {{ $t(transformStatus(match.status)) }}
        </span>
      </p>

      <div
        :class="[
          'flex w-fit lg:ml-10 lg:w-20 absolute top-2 left-3 gap-x-1.5 justify-center items-center',
          showGroup ? 'lg:!static' : 'lg:hidden',
        ]"
      >
        <p
          v-if="showGroup"
          class="rounded border-primary border w-fit text-primary lg:px-2 lg:py-0.5 ml-auto px-1 text-[10px] lg:text-sm font-normal lg:font-medium"
        >
          {{ match.groupName }}
        </p>
        <div class="w-20 text-gray-400 text-xs sm:hidden" v-if="match.date && !showDateTime(matchTime)">
          {{ matchTime }}
        </div>
        <div v-else class="w-20 text-gray-400 text-xs sm:hidden">-</div>
      </div>
    </div>
  </component>
</template>

<style scoped></style>
